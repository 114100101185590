(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["pmrpc"] = factory();
	else
		root["pmrpc"] = factory();
})((typeof self !== "undefined" ? self : (typeof global !== "undefined" ? global : this)), function() {
return 